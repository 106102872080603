import $ from 'jquery';
import Anchor from '../Components/_Anchor.js';
import Swiper from '../Components/_Slider.js';
import lightbox from 'lightbox2/dist/js/lightbox';
import 'lightbox2/dist/css/lightbox.min.css';

$(function () {
	// ハンバーガーメニュー
	$('.js-header-open').on('click', function () {
		let target = $(this).data('target');
		$(this).toggleClass('is-open');
		$('.c-header__nav[data-header="' + target + '"]').toggleClass('is-open');
	});

	$('.c-header__link').on('click', function () {
		let target = $(this).closest('.c-header__nav').data('header');
		$('.c-header__nav[data-header="' + target + '"]').removeClass('is-open');
	});

	// lightbox
	lightbox.option({
		resizeDuration: 200,
		wrapAround: true,
	});
});

$(window).scroll(function (){
	$('.fadein').each(function(){
		var elemPos = $(this).offset().top,
		scroll = $(window).scrollTop(),
		windowHeight = $(window).height();

			if (scroll > elemPos - windowHeight + 150){
				$(this).addClass('scrollin');
			}
	});
});

// アニメーション
$(function () {
  $(window).scroll(function(){
    var windowHeight = $(window).height(),
    scrollY = $(window).scrollTop();
    $('.scroll_fadein').each(function(){
      var thisPosition = $(this).offset().top;
      if(scrollY > thisPosition - windowHeight){
        $(this).addClass('fadein_animation_start');
      }
    });
  });
});