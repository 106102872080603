import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const slider1 = new Swiper('.c-slider1', {
	loop: true,
	spaceBetween: 10,
	centeredSlides: true,
	slidesPerView: 1.02,
	speed: 600,
	threshold:10,
	// effect: 'fade',
	autoplay: {
		delay: 5000,
		disableOnInteraction: false, // スライドに触っても自動再生を停止しない
	},
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: 'clickable',
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		768: {
			slidesPerView: 1.45,
			spaceBetween: 30,
			// effect:'slide',
		},
	},
});

const slider2 = new Swiper('.c-slider2', {
	loop: true,
	speed: 600,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false, // スライドに触っても自動再生を停止しない
	},
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: 'clickable',
	},
	breakpoints: {
		768: {
			slidesPerView: 1.55,
			spaceBetween: 20,
			centeredSlides: true,

		},
	},
});